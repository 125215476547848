import { ApolloAuth0Provider } from '@unpublished/apollo-auth0'
import {
  UserProfile,
  WhenAuthenticated,
  WhenUnauthenticated,
} from '@unpublished/auth0-helpers'
import { AppContainer, GlobalStyle } from '@unpublished/common-components'
import React, { useMemo } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'

import { FixedWidthPageContainer } from '../common/common-components'
import { loadConfig } from './config'

// TODO: Find a way to detect missing 'write:data' scope, and in that case, hide
// relevant UI elements

const UserProfileContainer = styled.div`
  float: right;
  width: 250px;
`

function Placeholder(): JSX.Element {
  const { dataLayerUrl } = useMemo(loadConfig, [])
  return (
    <div>
      <div>Hello World!</div>
      <div>Data Layer URL: {dataLayerUrl}</div>
    </div>
  )
}

function Home({ authEnabled }: { authEnabled?: boolean }): JSX.Element {
  return (
    <FixedWidthPageContainer>
      {authEnabled ? (
        <>
          <UserProfileContainer>
            <UserProfile />
          </UserProfileContainer>
          <WhenUnauthenticated>Please log in.</WhenUnauthenticated>
          <WhenAuthenticated>
            <Placeholder />
          </WhenAuthenticated>
        </>
      ) : (
        <Placeholder />
      )}
    </FixedWidthPageContainer>
  )
}

export function App(): JSX.Element {
  const { dataLayerUrl, authEnabled, auth0 } = useMemo(loadConfig, [])

  return (
    <BrowserRouter>
      <ApolloAuth0Provider
        withAuth0Credentials={authEnabled}
        url={dataLayerUrl}
        authConfig={auth0}
        scope="write:data"
      >
        <AppContainer>
          <GlobalStyle />
          <Routes>
            <Route path="/" element={<Home authEnabled={authEnabled} />} />
          </Routes>
        </AppContainer>
      </ApolloAuth0Provider>
    </BrowserRouter>
  )
}
