// @ts-ignore
import envFlag from 'node-env-flag'

export function loadConfig(): {
  dataLayerUrl: string
  authEnabled: boolean
  auth0: {
    domain: string
    clientId: string
    audience: string
  }
} {
  const dataLayerUrl = process.env.REACT_APP_METRO__DATA_LAYER_URL
  if (!dataLayerUrl) {
    throw Error('REACT_APP_METRO__DATA_LAYER_URL is not set')
  }

  return {
    dataLayerUrl,
    authEnabled: envFlag(process.env.REACT_APP_METRO__AUTH_ENABLED, true),
    auth0: {
      domain:
        process.env.REACT_APP_METRO__AUTH0_DOMAIN ??
        'metabolize-sandbox.us.auth0.com',
      clientId:
        process.env.REACT_APP_METRO__AUTH0_CLIENT_ID ??
        'JbLORhKA4loiDTLFCiB92cklbtisYwoO',
      audience:
        process.env.REACT_APP_METRO__AUTH0_AUDIENCE ??
        'https://metro-data-layer.curvewise.com/v1',
    },
  }
}
